@font-face {
  font-family: 'icomoon';
  src: url("../../styles/fonts/icomoon.eot?f84oxd");
  src: url("../../styles/fonts/icomoon.eot?f84oxd") format("embedded-opentype"), url("../../styles/fonts/icomoon.ttf?f84oxd") format("truetype"), url("../../styles/fonts/icomoon.woff?f84oxd") format("woff"), url("../../styles/fonts/icomoon.svg?f84oxd#icomoon") format("svg");
  font-weight: normal;
  font-style: normal;
}

.bcReviewScores {
  margin-bottom: 0.3125rem;
  clear: both;
  display: flex;
  width: 100%;

  .user-review {
    width: 100%;
    display: flex;
    align-items: center;
  }

  .user-review .user-rating {
    display: flex;
    position: relative;
    font-family: 'icomoon' !important;
    font-size: 1rem;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
  }

  .tooltipHandler {
    text-decoration: underline;
    cursor: pointer;
    margin-left: 0.625rem;
  }

  .user-review .user-rating:before {
    content: "\e901 \e901 \e901 \e901 \e901";
    color: #CECECE;
  }

  .user-review .user-rating:after {
    position: absolute;
    top: 0;
    left: 0;
    color: #86694B;
  }

  .user-review .user-rating.user10:after {
    content: "\e901 \e901 \e901 \e901 \e901";
  }

  .user-review .user-rating.user9:after {
    content: "\e901 \e901 \e901 \e901 \e900";
  }

  .user-review .user-rating.user8:after {
    content: "\e901 \e901 \e901 \e901";
  }

  .user-review .user-rating.user7:after {
    content: "\e901 \e901 \e901 \e900";
  }

  .user-review .user-rating.user6:after {
    content: "\e901 \e901 \e901";
  }

  .user-review .user-rating.user5:after {
    content: "\e901 \e901 \e900";
  }

  .user-review .user-rating.user4:after {
    content: "\e901 \e901";
  }

  .user-review .user-rating.user3:after {
    content: "\e901 \e900";
  }

  .user-review .user-rating.user2:after {
    content: "\e901";
  }

  .user-review .user-rating.user1:after {
    content: "\e900";
  }

  .user-review span {
    font-size: 0.813rem;
    line-height: 160%;
    // height: 1.875rem;
    color: #4A4A4A;
    display: block;
    margin-left: 0.625rem;
  }


}
