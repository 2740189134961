.progressMeterContainer {
  width: 100%;
  display: flex;
  height: 20px;
  align-items: center;

  .progressMeterBar {
    flex: 2;
    height: 100%;
    background-color: #e8e8e8;
    position: relative;

    .progressMeterBarProgress {
      position: absolute;
      left: 0;
      top: 0;
      height: 100%;
      background-color: #86694B;
    }
  }

  .progressMeterLabel {
    flex: 2;
    font-size: 0.813rem;
    line-height: 1.25rem;
    margin-bottom: 0.3125rem;
    margin-left: 10px;
    color: #202020;
  }
}
