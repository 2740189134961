.card {
  background-color: #ffffff;
  border: 1px solid #e8e8e8;
  text-shadow: none;
  max-height: 700px;
  width: max-content;
  overflow: visible;

  li:hover {
    background-color: rgba(32, 32, 32, 0.2);
  }
}

.dropDownContainer {
  max-height: 40px;
  align-items: center;
}

.dropDownLink {
  span, a, div {
    color: #0a0a0a !important;
    text-shadow: none !important;
  }

  text-decoration: none;
  text-shadow: none !important;
  display: flex;
  flex-direction: column;
  justify-content: center;

  &Light {
    color: #ffffff !important;

    &:hover {
      text-decoration: none;
    }
  }

  &:hover {
    text-decoration: none;
  }
}

