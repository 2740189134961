.top {
  position: relative;
  width: 100%;
  height: 45vh;
  @media screen and (max-width: 1024px) {
    height: 15vh;
  }
}

.content {
  h3 {
    width: 100%;
  }

  :global {

    .rating {
      position: relative;
      width: 100%;
      max-width: 100%;
      // width: 135%;
      // left: -17%;
      @media screen and (max-width: 1024px) {
        flex-wrap: wrap;
        width: 100%;
        left: 0;
      }

      .small-column {
        @media screen and (max-width: 1024px) {
          flex-direction: column;
        }
      }

      .content {
        width: 50%;
        @media screen and (max-width: 1024px) {
          width: 100%;
          margin-bottom: 2rem;
        }
      }

      .rating-badge {
        position: relative;
        fill: #b38e56;
        width: 16.66667%;
        float: left;
      }

      .rating-description {
        width: 83.33333%;
        padding-right: 1.25rem;
        padding-left: 1.25rem;
      }
      .user-ratings{
        @media screen and (max-width: 1024px) {
          width: 100%;
        }
      }
    }

    .people.column, .people.columns {
      display: -ms-flexbox;
      display: flex;
      -ms-flex-wrap: wrap;
      flex-wrap: wrap;
      -ms-flex-direction: row;
      flex-direction: row;
      -ms-flex-align: start;
      align-items: flex-start;
      position: relative;
      width: 135%;
      left: -17%;

      @media screen and (max-width: 1024px) {
        flex-wrap: wrap;
        width: 100%;
        left: 0;
      }
    }

    .people.column .people-info, .people.columns .people-info {
      display: -ms-flexbox;
      display: flex;
      -ms-flex-wrap: wrap;
      flex-wrap: wrap;
      padding-bottom: 1.25rem;


      .column {
        width: 50%;
        padding-right: 1.25rem;
        padding-left: 1.25rem;

        &:nth-child(3) {
          width: 100%;
          padding: 1.25rem 1.25rem 0;
          text-align: justify;
        }

        a {
          position: relative;
          margin-top: 2rem;
          font-weight: normal;
          text-decoration: unset;
        }

        a::after {
          content: ' ';
          width: 0;
          height: 0;
          border-left: 4px solid transparent;
          border-right: 4px solid transparent;
          position: absolute;
          bottom: 8px;
          margin-left: 4px;
          border-top: 4px solid #202020;
        }
      }

      .large-12 {
        display: -ms-flexbox;
        display: flex;
        -ms-flex-direction: column;
        flex-direction: column;
        -ms-flex-pack: end;
        justify-content: flex-end;
      }
    }

    .expert-image {
      text-align: center;
    }

    .small-12 {
      width: 50%;

    }

    .large-8 {
      width: 33.33333%;
      @media screen and (max-width: 1024px) {
        width: 100%;
      }
    }


    .message {
      font-style: italic;
    }

    .headers-index {
      display: -ms-flexbox;
      display: flex;
      padding: 0;
      margin: 0 auto;
      -ms-flex-direction: row;
      flex-direction: row;
      -ms-flex-wrap: wrap;
      flex-wrap: wrap;
      border-top: 1px solid #86694B;
      border-bottom: 1px solid #86694B;
      position: relative;
      // width: 135%;
      // left: -17%;

      @media screen and (max-width: 1024px) {
        width: 100%;
        left: 0;
      }
    }

    .about-people {
      cursor: pointer;
    }

    .headers-index > a {
      -ms-flex: 0;
      flex: 0;
      white-space: nowrap;
      text-align: center;
      padding: 0.6em;
      color: #86694B;

      font-family: "PPFragment", serif;
      font-size: 1.25rem;
      -ms-flex-item-align: start;
      align-self: flex-start;
      line-height: inherit;
      font-weight: normal;
      text-decoration: none;
      cursor: pointer;
    }

    #about .section {
      padding: 2rem;
      max-width: 66%;
      margin: 0 auto;

      &.rating {
        max-width: 100%;
      }

      @media screen and (max-width: 1024px) {
        padding: 1.5rem 0;
        max-width: 100%;
      }
    }
  }

  padding: 2rem;
  // width: 66.6%;
  display: flex;
  flex-direction: column;
  margin: auto;
  font-weight: normal;
  @media screen and (max-width: 1024px) {
    width: 100%;
    padding: .5rem;
  }

  img {
    width: 100%;
    // height: 599.2px;
    @media screen and (max-width: 1024px) {
      height: 30vh;
      object-fit: cover;
    }
  }

  .hide {
    display: none;
  }

  .show {
    display: flex;
  }

  .avatar {
    border-radius: 50%;
    height: unset;
  }

  .welcome {
    text-align: center;
    margin-bottom: 2rem;
    font-weight: bold;
  }

  h2 {
    line-height: 160%;
    font-weight: normal;
    margin: 1em 0;
    font-size: 1.9rem;
    padding-left: 0;
  }

  h3 {
    font-size: 1.7rem;
    text-align: left;
    font-family: "PPfragment", Arial, sans-serif;
    margin: 1.2em 0 0.4em;
    padding-left: 0;
  }

  p {
    margin-bottom: 1rem;
    font-size: 0.938rem;
    text-rendering: optimizelegibility;
    line-height: 1.5rem;
    text-align: justify;
  }

  table {
    border-collapse: separate;
    text-indent: initial;
    border-spacing: 2px;
  }

  tr {
    display: table-row;
    vertical-align: inherit;
    border-color: inherit;
  }

  th, td {
    margin: 0;
    padding: 0;
  }

  table, th, td {
    border: 1px solid #202020;
  }

  a {
    color: #202020;
    text-decoration: underline;
  }

  ol {
    margin-left: 1.25rem;
    list-style-type: decimal;
  }
}
