.container {
  display: flex;
  position: absolute;
  top: -1px;
  @media screen and (max-width: 1024px) {
    top: -2px
  }
  width: calc(100% - 6px);

  .leftSide {
    z-index: 1;
    width: calc(50%);
    height: 16px;
    border-top: 2px solid rgba(212, 169, 118, 1);
    border-right: 2px solid rgba(212, 169, 118, 1);
    border-top-right-radius: 4px;
    margin-right: -4px;
    transform: skewX(30deg);
  }

  .rightSide {
    z-index: 1;
    width: calc(50%);
    height: 16px;
    border-top: 2px solid rgba(212, 169, 118, 1);
    border-left: 2px solid rgba(212, 169, 118, 1);
    border-top-left-radius: 4px;
    transform: skewX(-30deg);
    margin-left: -4px;
  }

  .centre {
    width: 7px;
    height: 16px;
    border-bottom: 1.8px solid rgba(212, 169, 118, 1);
    display: flex;
    justify-content: flex-start;
    flex-direction: column;

    .hole {
      @media screen and (max-width: 1024px) {
        top: -2px;
        width: 0;
        height: 0;
        transform: translateX(-5px) translateY(-1px);
        border-left: 9px solid transparent;
        border-right: 7px solid transparent;
        border-radius: 0px;
        border-top: 9.5px solid #faf8f4
      }
      position: relative;
      bottom: -.5px;
      width: 0;
      height: 0;
      transform: translateX(-4.5px) translateY(-2px);
      border-left: 9px solid transparent;
      border-right: 7px solid transparent;
      border-radius: 0px;
      border-top: 10.5px solid #faf8f4

      // transform: translateX(-2px);
      // border-left: 6px solid transparent;
      // border-right: 6px solid transparent;
      // border-top: 7.5px solid #faf8f4;
    }
  }

  &.dark {
    .leftSide {
      z-index: 1;
      border-top-width: 1px;
      border-right-width: 1px;
      border-color: #86694B;
    }

    .centre {
      border-bottom-width: 1px;
      border-color: #86694B;
    }

    .rightSide {
      z-index: 1;
      border-top-width: 1px;
      border-left-width: 1px;
      border-color: #86694B;
    }
  }
}



