

.subtitleMb {
  font-size: 0.813rem;
  color: #202020;
}




.subtitle {
  font-size: 0.813rem;
  color: #202020;
}

.blockWrapper {
  position: relative;
  width: 100%;
  height: 100%;
  cursor: pointer;
  @media screen and (max-width: 1024px) {
    flex-direction: column;
    padding: .5rem 1rem;
    margin-top: 3rem;
  }
  background-color: white;
  box-shadow: 0 5px 5px 5px rgba(0, 0, 0, 0.02);
  display: flex;
  padding: .5rem 2rem;

  .userBioImageWrapper {
    min-width: 120px;
    max-width: 120px;
    height: 120px;
    overflow: hidden;
    position: relative;
    left: -45px;
    top: -45px;
    box-shadow: 0 5px 5px 5px rgba(0, 0, 0, 0.02);

    @media screen and (max-width: 1024px) {
      top: -32px;
      left: 50%;
      width: 140px;
      height: 140px;
      transform: translateX(-50%);
    }
  }


  .authorBlockContent {
    font-size: 13px;
    line-height: 24px;
    margin-left: -24px;
    @media screen and (max-width: 1024px) {
      margin-left: 0;
      margin-top: -32px;
    }
    color: #0a0a0a;

    .authorBlockIcon {
      position: absolute;
      right: 8px;
      top: 8px;
      @media screen and (max-width: 1024px) {
        top: 8px;
        right: 16px;
      }
      font-size: 32px;
      color: #002F4A;
    }

    .authorBlockName {
      font-size: 24px;
      line-height: 28px;
      margin-bottom: .25rem;
      @media screen and (max-width: 1024px) {
        text-align: center;
      }
    }

    .authorBlockOverline {
      color: #9b9b9b;
      @media screen and (max-width: 1024px) {
        margin-top: .5rem;
        text-align: center;
      }
    }

    .authorBlockText {
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
      @media screen and (max-width: 1024px) {
        text-align: justify;
      }

    }

    .authorBlockReadMore {
        text-decoration: underline;
        color: #0a0a0a;
      @media screen and (max-width: 1024px) {
        text-align: center;
        margin-bottom: 1rem;
      }

    }


  }
}