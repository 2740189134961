.subtitleMb {
  font-size: 0.813rem;
  color: #202020;
}

.userBioImageWrapper {
  position: relative;
  width: 42px;
  height: 42px;
  border-radius: 50%;
  overflow: hidden;
  margin-right: .5rem;
}

.userBioImageLargeWrapper {
  position: relative;
  width: 64px;
  height: 64px;
  border-radius: 50%;
  overflow: hidden;
  margin-right: .5rem;
}


.subtitle {
  font-size: 0.813rem;
  color: #202020;

  a {
    color: #202020;
    text-decoration: underline;
  }
}

.authorPopup {
  width: 400px;
  height: 175px;
  background-color: white;
  padding: .5rem;
  box-shadow: 0 0 0 1px #e8e8e8 inset;
  display: flex;
  position: relative;

  .authorPopupContent {
    max-width: 80%;
    font-size: 13px;
    line-height: 24px;
    margin-left: .25rem;
    color: #0a0a0a;

    .authorPopupIcon {
      position: absolute;
      right: 8px;
      top: 8px;
      font-size: 32px;
      color: #3C6562;
    }

    .authorPopupName {
      font-size: 24px;
      line-height: 28px;
    }

    .authorPopupOverline {
      color: #9b9b9b;
      margin-top: .25rem;
    }

    .authorPopupText {
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;

    }

    .authorPopupReadMore {
      a {
        text-decoration: underline;
        color: #0a0a0a;
      }

    }


  }
}