.listContainer {
  min-height: calc(100vh - 310px);
  margin: -115px auto 0 auto;
  @media screen and (min-width: 1024px) and (max-width: 1440px) {
    margin: -70px auto 0 auto;
  }
  max-width: 1020px;
}


.emptyList {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 0 1rem;
  margin-top: 130px;

  h2 {
    font-size: 2rem;
    line-height: 1.4;
  }

  p {
    font-size: 0.938rem;
    line-height: 1.4;
  }
}

.recommendationItem {
  display: flex;
  margin-top: 16px;
  margin-bottom: 16px;
  width: calc(50% - 28px);
  @media screen and (max-width: 1024px) {
    width: 100%;
    margin-right: 0px !important;
    margin-left: 0px !important;
  }
}

.headingContainer {
  height: 430px;
  padding-top: 80px;
  @media screen and (max-width: 1024px) {
    height: 360px;
  }
}

// Naming according to article type (lowercase)
.airline,
.airlines {
  background: #00454A;
  height: 430px;
  @media screen and (max-width: 1024px) {
    height: 360px;
  }
}

.hotel,
.hotels {
  background-color: #7E183D;
}

.lifestyle,
.guides,
.trains,
.cruises,
.airports,
.dining,
.finest, .noType {
  background-color: #86694B;
}

.destinations {
  background-color: #385179;
}

.noReviewsFound {
  font-size: 2rem;
  color: #ffffff;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}

.heading {
  font-size: 3.125rem;
  line-height: 1.4;
  color: #ffffff;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;

  @media screen and (max-width: 1024px) {
    padding: 0 10px;
    font-size: 2.188rem;
  }

  .airlineIcon,
  .icon {
    display: inline-flex;
    border: 2px solid #fff;
    border-radius: 50%;
    width: 50px;
    height: 50px;
    margin-right: 15px;
    min-width: 50px;

    @media screen and (max-width: 1024px) {
      margin-right: 8px;
      min-width: 2.188rem;
      width: 2.188rem;
      height: 2.188rem;

      svg {
        width: 17px;
      }
    }
  }

  .airlineIcon {
    svg {
      transform: rotate(90deg);
    }
  }
}

.ratingBadge {
  position: absolute;
  right: 0;
  top: 16px;
  max-width: 115px;
  width: 100%;
  margin-right: 1.25rem;
  @media screen and (max-width: 1024px) {
    max-width: 100px;
  }
  @media screen and (min-width: 720px) and (max-width: 1199px) {
    max-width: 115px;
  }
  @media screen and (max-width: 720px) {
    max-width: 80px;
    top: -5px;
    right: -20px
  }

  span {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -40%);
    font-size: 1.725rem;
    @media screen and (max-width: 1024px) {
      font-size: 1.625rem;
    }
    font-weight: bold;
    color: #86694B;
  }

  svg {
    fill: #86694B
  }

}

.list {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 0 1rem;

  @media screen and (max-width: 1024px) {
    padding-top: 20px;
  }
}

.review {
  cursor: pointer;
  width: calc(50% - 28px);
  @media screen and (max-width: 1024px) {
    width: 100%;
  }

  &:nth-child(odd) {
    margin-right: 56px;

    @media screen and (max-width: 1024px) {
      margin-right: 0;
    }
  }

  margin-bottom: 56px;

  background-color: #ffffff;
}

.header {
  display: flex;
  align-items: center;
  padding: 1.25rem 0 2.5rem 1.25rem;
  color: #9b9b9b;
  font-size: 0.813rem;
  @media screen and (max-width: 1024px) {
    padding: 1rem 0 0.25rem 1rem;
  }
}


.reviewTitle {
  margin: 1rem auto 1.563rem;
  width: 50%;
  font-size: 1.75rem;
  line-height: 2.5rem;
  text-align: center;
  color: #202020;
  @media screen and (min-width: 720px) and (max-width: 1199px) {
    font-size: 1.75rem !important;
    line-height: 2.5rem !important;
  }
  @media screen and (max-width: 1024px) {
    font-size: 1.5rem;
    line-height: 2rem;
    width: 70%;
  }
}

.reviewContent {
  width: 66%;
  color: #202020;
  text-align: center;
  font-size: 0.938rem;
  font-weight: 400;

  margin: 1.563rem auto 2.375rem;
  @media screen and (min-width: 1024px) and (max-width: 1199px) {
    width: 100% !important;
  }
  @media screen and (max-width: 1024px) {
    padding: 0 1rem;
  }
  @media screen and (max-width: 600px) {
    width: 100%;
  }

}

.a {
  color: #202020;

  &:hover {
    text-decoration: none;

  }
}

.reviewContainer {
  width: 100%;
  height: 100%;
  display: flex;
  background-color: white;
  box-shadow: 0 5px 5px 5px rgba(0, 0, 0, 0.02);
}

.reviewImage {
  width: 106%;
  position: relative;
  left: -3%;

}

.reviewContentContainer {
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
}

@keyframes pulseScaleOut {
  0% {
    -webkit-transform: scale(0);
    transform: scale(0);
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 0;
  }
}

.loader {
  margin: auto;
  width: 60px;
  height: 60px;
  background-color: #c19237;
  border-radius: 100%;
  animation: pulseScaleOut 1s infinite ease-in-out;
}

.filtersContainer {
  display: flex;
  flex-wrap: wrap;
  max-width: calc(1020px - 2rem);
  padding: 1rem;
  justify-content: center;
  margin: auto;
  @media screen and (max-width: 1024px) {
    display: none;
  }


  .filter {
    min-width: 300px;
    margin: 0 1rem;
    background-color: #FFFFFF;
    border-radius: 12px;
  }

  .filterSmaller {
    min-width: 200px;
    margin: 0 1rem;
    background-color: #FFFFFF;
    border-radius: 12px;
  }

  .filtersSubmit {
    min-width: 200px;
  }
}

.filtersContainerMobile {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  height: 100%;
  padding: 20px 10px;


  .filterSmaller, .filter {
    width: 100%;
    margin-top: 1rem;
  }

  .filtersSubmit {
    width: 75%;
    margin-top: 1rem;
    min-height: 75px;
  }

  .closeMobileFilters {
    position: absolute;
    right: 0;
    top: 0;
    align-self: flex-end;
  }

  .sortingDirectionIcon {
    position: absolute;
    right: 0;
  }
}

.mobileFiltersWrapper {
  @media screen and (min-width: 1024px) {
    display: none;
  }
  color: #FFFFFF;
  padding: 0 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  .filtersButton {
    height: 50px;
    font-size: 20px;
    display: flex;
    align-items: center;
  }

  .sorting {
    display: flex;
  }
}
