.vintageButtonWrapper {
  cursor: pointer;
  width: 100%;
  padding: 1rem 0;
  text-align: center;
  border-bottom: 2px solid rgba(212, 169, 118, 1);
  color: rgba(212, 169, 118, 1);
  overflow: hidden;

  span {
    display: inline-block;
    &::first-letter {
      text-transform: capitalize;
    }
  }

  .heading {
    display: flex;
    width: 100%;

    .leftSide {
      width: calc(50%);
      height: 16px;
      border-top: 2px solid rgba(212, 169, 118, 1);
      border-right: 2px solid rgba(212, 169, 118, 1);
      border-top-right-radius: 4px;
      margin-right: -4px;
      transform: skewX(30deg);
    }

    .rightSide {
      width: calc(50%);
      height: 16px;
      border-top: 2px solid rgba(212, 169, 118, 1);
      border-left: 2px solid rgba(212, 169, 118, 1);
      border-top-left-radius: 4px;
      transform: skewX(-30deg);
      margin-left: -4px;
    }

    .centre {
      width: 8.5px;
      height: 18px;
      border-bottom: 2px solid rgba(212, 169, 118, 1);
    }
  }

  &.dark {
    border-bottom: 2px solid rgba(134, 105, 75, 1);
    color: rgba(134, 105, 75, 1);

    .leftSide {
      border-top: 2px solid rgba(134, 105, 75, 1);
      border-right: 2px solid rgba(134, 105, 75, 1);
    }

    .rightSide {
      border-top: 2px solid rgba(134, 105, 75, 1);
      border-left: 2px solid rgba(134, 105, 75, 1);
    }

    .centre {
      border-bottom: 2px solid rgba(134, 105, 75, 1);
    }
  }
}




