
.card {
  background-color: white;
  color: #202020;
  display: block;

  &:hover {
    text-decoration: none;
  }

  width: 100%;
}

.imageContainer {
  position: relative;
  width: calc(100% + 30px);

}

.typesList {
  width: 49%;
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 30px;
  /* or 160% */
  text-align: center;
  margin-top: 25px;
  margin-bottom: 25px;

}

.readMore {
  margin-bottom: 38px;
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 24px;
  /* identical to box height, or 160% */

  text-align: center;
  text-decoration-line: underline;

  color: #000000;
}

.type {
  color: #9b9b9b;
  font-size: 13px;
  line-height: 24px;
}

.title {
  font-weight: 400;
  font-size: 28px;
  line-height: 40px;

  /* or 143% */
  margin-top: 39px;
  text-align: center;

  color: #1E1F20;


}

.subTitle {
  margin-top: 8px;
  margin-bottom: 30px;
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 21px;
  /* identical to box height, or 162% */

  text-align: center;

  color: #000000;

  opacity: 0.5;
}

.content {
  @media screen and (min-width: 1024px) and (max-width: 1300px) {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
  }
}

.icon {
  border-radius: 50%;
  display: flex;
  position: absolute;
  top: 15px;
  right: 20px;
  width: 34px;
  height: 34px;
  z-index: 1;

  svg {
    font-size: 18px;
  }
}

.text {
  padding-bottom: 1rem;

  p {
    color: #202020;
  }
}
