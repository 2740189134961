.flag32 {
  display: inline-block !important;
  position: relative;
  top: 6px;
  -ms-transform: scale(0.7);
  transform: scale(0.7);
  margin-top: -12px;
}

.itemChild {
  &:not(:last-of-type) { 
    border-bottom: 1px solid #F1F1F1;
  }
}
