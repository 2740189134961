.option {
  ul {
    li {
      div {
        color: #00454A;
        font-weight: 600;
      }

      ul {
        li {
          border-left: 4px solid transparent;
        }

        li:not(:first-child) {
          padding-left: 32px;
        }

        li:first-child {
          font-weight: 600;
        }

        li:first-child:hover, li:hover {
          border-left: 4px solid #0a0a0a;
          text-decoration: underline;
          font-weight: 600;

        }

        li:first-child:hover ~ li {
          border-left: 4px solid #0a0a0a;
          text-decoration: underline;
          font-weight: 600;
        }
      }
    }

  }

}

