.top {
  position: relative;
  width: 100%;
  height: 45vh;
  @media screen and (max-width: 1024px) {
    height: 15vh;
  }
}

.content {
  padding: 2rem;
  width: 66.6%;
  display: flex;
  flex-direction: column;
  margin: auto;
  @media screen and (max-width: 1024px) {
    width: 100%;
    padding: .25rem;
  }
  h1 {
    margin-top: 1em;
    font-size: 3rem;
    line-height: 1.25em;
    margin-bottom: 1rem;
    text-align: center;
    font-family: "PPFragment", Arial, sans-serif;
  }

  h3 {
    font-size: 1.7rem;
    text-align: left;
    font-family: "PPFragment", Arial, sans-serif;
    margin: 1.2em 0 0.4em;
  }

  h4 {
    text-align: left;
    font-size: 1rem;
    font-family: inherit;
    margin-bottom: 4px;
  }

  p {
    margin-bottom: 1rem;
    font-size: 0.938rem;
    text-rendering: optimizelegibility;
    line-height: 1.5rem;
    text-align: justify;
  }

  table {
    border-collapse: separate;
    text-indent: initial;
    border-spacing: 2px;
  }

  tr {
    display: table-row;
    vertical-align: inherit;
    border-color: inherit;
  }

  th, td {
    margin: 0;
    padding: 0;
  }

  table, th, td {
    border: 1px solid #202020;
  }

  a {
    font-weight: bold;
    color: #202020;
    text-decoration: underline;
  }

  ol {
    margin-left: 1.25rem;
    list-style-type: decimal;
  }

  ul {
    margin-left: 1.25rem;
  }

  li {
    font-size: 0.938rem;
  }
}
