.bookNowWrapper {
  position: relative;
  height: 100%;
  cursor: pointer;
  background-color: white;
  box-shadow: 0 5px 5px 5px rgba(0, 0, 0, 0.02);
  display: flex;
  width: calc(53% - 2.5rem);
  font-size: 0.938rem;
  line-height: 160%;
  font-weight: 400;
  color: #202020;
  padding: 1rem;
  margin: auto auto 1rem;

  a {
    text-decoration: none !important;
    color: white !important;
  }

  @media screen and (max-width: 1024px) {
    display: flex;
    flex-direction: column;
    width: calc(100% - 2rem);
  }

  .bookNowHotelName {
    position: relative;
    font-size: 1.25rem;
    width: 100%;
    line-height: 1.25rem;
    display: flex;
    align-items: center;
    text-align: left;
    margin-right: 1rem;
    @media screen and (max-width: 1024px) {
      width: 100%;
      text-align: center;
      justify-content: center;
      margin-bottom: 1rem;
    }
  }

}