
.menu {
  li {
    position: relative;

    a {
      padding: 0.7rem 1rem;
      line-height: 1
    }
  }
}

.headerContentContainer {
  max-width: 1440px;
  margin: auto;

}

.transparentHeader {
  position: fixed;
  background: linear-gradient(180deg, rgba(30, 31, 32, 0.4) 0%, rgba(30, 31, 32, 0.1) 100%);
  @media screen and (max-width: 1024px) {
    background: linear-gradient(180deg, rgba(30, 31, 32, 0.2) 0%, rgba(30, 31, 32, 0.4) 100%);
  }
  top: 0;
  color: white;
  z-index: 100;
  transition: 300ms;
}

.hidden {
  top: -72px !important;
  transition: 1s;
}

.header {
  position: sticky;
  background-color: white;
  z-index: 1300;
  transition: 300ms;
  top: 0;
}

.searchContainer {
  width: 350px;
  height: 45px;
  position: absolute;
  bottom: -45px;
}

.loginButton {
  min-width: 105px;
  border-radius: 12px;
  max-width: 200px;
  font-size: 15px;
  line-height: 24px;
  font-weight: 600;
  padding: 8px 24px 8px 24px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  background-color: #3C6562;
  @media screen and (min-width: 1024px) and (max-width: 1440px) {
    max-width: 150px;
    font-size: 12px;
    line-height: 18px;
  }
}

.navItem {
  display: flex;
  align-items: center;
  position: relative;
  cursor: pointer;
  font-size: 16px;
  white-space: nowrap;

  .searchIcon {
    width: 50px;
  }

  .darkSearchIcon {
    fill: #202020;
    filter: none;
  }
  @media screen and (min-width: 1024px) and (max-width: 1440px) {
    font-size: 12px;
  }

  div:first-child {


    a {
      color: white;
    }
  }

  a {
    span {
      color: white;

      &:hover {
        text-decoration: none;
      }
    }
  }
}

.darkFont {
  color: #202020;
  text-shadow: none !important;
}


.closeDialog {
  position: absolute !important;
  top: 8px;
  right: 8px;
  z-index: 1;
  border-radius: 50%;
  background-color: #D4A976 !important;
  color: white !important;

  @media screen and (max-width: 1024px) {
    top: 15px;
  }

  &:hover {
    background-color: #86694B;
  }
}
