@font-face {
  font-family: 'icomoon';
  src: url("../../styles/fonts/icomoon.eot?f84oxd");
  src: url("../../styles/fonts/icomoon.eot?f84oxd") format("embedded-opentype"), url("../../styles/fonts/icomoon.ttf?f84oxd") format("truetype"), url("../../styles/fonts/icomoon.woff?f84oxd") format("woff"), url("../../styles/fonts/icomoon.svg?f84oxd#icomoon") format("svg");
  font-weight: normal;
  font-style: normal;
}

.bcReviewScores {
  clear: both;

  &:last-of-type {
    margin-bottom: 0;
  }

  display: flex;
  align-items: center;

  /*
  .stars9 {
      width: 113px;
      height: 20px;
      float: left;
      background-image: url("../img/8-stars.svg");
      background-repeat: no-repeat;
      background-size: 113px 20px;
      margin-right: 10px;
  }
  */
  .stars0 {
    float: left;
    display: inline-block;
    position: relative;
    font-family: 'icomoon' !important;
    font-size: 1.25rem;


    &Small {
      font-size: .75rem;
    }


    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;


    &:after {
      position: relative;
      top: 0;
      left: 0;
      color: #86694b;
    }

    &Inactive:after {
      position: relative;
      top: 0;
      left: 0;
      color: #86694b !important;
    }

  }

  .stars10 {
    &:after {
      content: "\e903 \e903 \e903 \e903 \e903";
    }
  }

  .stars9 {
    &:after {
      content: "\e903 \e903 \e903 \e903 \e902";
    }
  }

  .stars8 {
    &:after {
      content: "\e903 \e903 \e903 \e903";
    }
  }

  .stars7 {
    &:after {
      content: "\e903 \e903 \e903 \e902";
    }
  }

  .stars6 {
    &:after {
      content: "\e903 \e903 \e903";
    }
  }

  .stars5 {
    &:after {
      content: "\e903 \e903 \e902";
    }
  }

  .stars4 {
    &:after {
      content: "\e903 \e903";
    }
  }

  .stars3 {
    &:after {
      content: "\e903 \e902";
    }
  }

  .stars2 {
    &:after {
      content: "\e903";
    }
  }

  .stars1 {
    &:after {
      content: "\e902";
    }
  }

  span {
    font-size: 0.875rem;
    line-height: 1.875rem;
    height: 1.875rem;
    color: transparent;
    display: block;

  }


}
