
.container {
  @media screen and (max-width: 1024px) {
    width: 100%;
  }
  width: 75%;
  font-size: 1.1875rem;
  line-height: 1.8125rem;
  font-weight: 400;
  color: #4a4a4a;
  padding-right: 1.25rem;
  padding-left: 1.25rem;
  margin: auto auto 1rem;

  span {
    p {
      margin: 0;
    }
  }


  .content {
    overflow-y: auto;
    max-height: 300px;
  }

  p, ul {
    @media screen and (max-width: 1024px) {
      width: 100%;
    }
    width: 100%;
    font-size: 1.1875rem;
    line-height: 1.8125rem;
    font-weight: 400;
    color: #4a4a4a;
    padding: 0;
    margin: auto auto 1rem;

    strong {
      font-weight: 700;
    }
  }

  ul {
    list-style: inside;
  }

}
