.arrow {
  height: 16px;
  position: relative;
}

.arrowBlack {
  fill: #202020;
  right: 0;
  position: relative;
  transform: rotate(-90deg);
}

.arrowWhite {
  fill: #ffffff;
  right: 0;
  position: relative;
  transform: rotate(-90deg);
}

.arrowBlackHeading {
  fill: #202020;
  right: 0;
  position: relative;
}
