.option {
  ul {
    li {
      div {
        color: #00454A;
        font-weight: 600;
      }

      ul {
        li {
          border-left: 4px solid transparent;
        }

        li:hover {
          border-left: 4px solid #0a0a0a;
          text-decoration: underline;
          font-weight: 600;

        }


      }

    }

  }

}

