.listContainer {
  min-height: calc(100vh - 310px);
  margin-top: -115px;
}

.headingContainer {
  height: 450px;
  padding-top: 160px;
  background-color: #385179;


  @media screen and (max-width: 1024px) {
    padding-top: 90px;
    height: 300px;
  }
}

.heading {
  font-size: 3.125rem;
  margin-bottom: 0.5em;
  line-height: 1.4;
  color: #ffffff;
  text-align: center;

  @media screen and (max-width: 1024px) {
    padding: 0 10px;
    font-size: 2.188rem;
  }
}

.list {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.loader {
  margin: auto;
  width: 60px;
  height: 60px;
  background-color: #c19237;
  border-radius: 100%;
  animation: pulseScaleOut 1s infinite ease-in-out;
}

.recommendationItem {
  display: flex;
  margin-top: 16px;
  margin-bottom: 16px;
  width: calc(50% - 28px);
  @media screen and (max-width: 1024px) {
    width: 100%;
    margin-right: 16px !important;
    margin-left: 16px !important;
  }
}
