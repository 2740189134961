.logo {
  width: 290px;
  height: 35px;
  fill: #ffffff;
  @media screen and (max-width: 1100px) {
    width: 200px;
  }
  @media screen and (max-width: 420px) {
    width: 180px;
  }
}

.logoMobile {
  width: 220px;
  height: 35px;
  fill: white;
  @media screen and (max-width: 420px) {
    width: 180px;
  }
  @media screen and (max-width: 320px) {
    width: 150px;
  }
}

.logoMobileBlack {
  width: 220px;
  height: 35px;
  fill: #202020;
  @media screen and (max-width: 420px) {
    width: 180px;
  }
  @media screen and (max-width: 320px) {
    width: 150px;
  }
}
.logoBlack {
  fill: #202020;
  @media screen and (max-width: 420px) {
    width: 180px;
  }
  @media screen and (max-width: 320px) {
    width: 150px;
  }
}

