.accordionTitle {
  border-bottom: 1px solid #F1F1F1;

  a {
    color: #202020;
    margin: 6px 0;
    display: flex;
    &:hover {
      text-decoration: none;
    }
  }
}

.searchContainer{
  height: 75px;
}

.menuContainer {
  max-height: calc(100vh - 60px);
  overflow-y: scroll;
}
