.closeDialog {
  position: absolute !important;
  top: 90px;
  right: 0;
  z-index: 1;
  color: #ffffff !important;
  background-color: #202020;
}

.fullscreenCarousel {
  max-height: 80vh !important;
  margin-top: auto !important;
  margin-bottom: auto !important;
}

.carouselWrapper {
  width: 100%;
  height: 100%;
  position: relative;
  transform: scale(1);
  @media screen and (max-width: 1024px) {
    height: 100%;
  }
}

.dialogPaper {
  background-color: #202020 !important;
}

