
.card {
  background-color: white;
  color: #202020;
  cursor: pointer;

  &:hover {
    text-decoration: none;
  }

  width: 100%;

  // height: 275px;
  // @media screen and (min-width: 1300px) {
  //   max-width: 800px;
  //   height: 300px;
  // }
  // @media screen and (min-width: 1000px) and (max-width: 1300px) {
  //   height: 250px;
  // }
  // @media screen and (max-width: 496px) {
  //   height: 450px;
  // }
  // @media screen and (min-width: 496px) and (max-width: 1000px) {
  //   height: 450px;
  // }
}

.recomendationContainer {
  width: 100%;
  height: 100%;
  background-color: white;
  box-shadow: 0px 5px 5px 5px rgba(0, 0, 0, 0.02);
}

.imageContainer {
  position: relative;
  width: 220px;
  height: 220px;


  .imageOut {
    top: -15px;
    left: -15px;

    @media screen and (max-width: 1300px) {
      top: 0px;
      left: 0px;
    }
  }

  @media screen and (max-width: 496px) {
    height: 208px;
    width: 100%;
    top: 0px;
    left: 0px;
  }
  @media screen and (min-width: 496px) and (max-width: 1024px) {
    height: 208px;
    width: 100%;
    top: 0px;
    left: 0px;
  }


}

.text {
  width: calc(100% - 220px);
  padding: 1.25rem 1.25rem 1.563rem 0;
  @media screen and (max-width: 496px) {
    width: 100%;
    padding-left: 1.25rem;
  }
  @media screen and (min-width: 496px) and (max-width: 1024px) {
    width: 100%;
    padding-left: 1.25rem;
  }
}

.type {
  color: #9b9b9b;
  font-size: 13px;
  line-height: 24px;
  padding-right: 33px;

  @media screen and (max-width: 1024px) {
    text-align: center;
    width: 100%;
  }
}

.title {
  margin: 0.938rem 0;
  font-size: 1.438rem;
  line-height: 2.063rem;
  max-width: 100%;
  @media screen and (max-width: 1024px) {
    text-align: center;
    width: 100%;
  }
  @media screen and (min-width: 1024px) and (max-width: 1300px) {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }

}

.content {
  font-size: 0.813rem;
  line-height: 21px;
  @media screen and (max-width: 1024px) {
    text-align: center;
    font-size: 0.938rem;
  }
  @media screen and (min-width: 1024px) and (max-width: 1300px) {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
  }
}

.icon {
  display: flex;
  position: absolute;
  top: 15px;
  right: 20px;
  width: 34px;
  height: 34px;
  z-index: 1;
  border-radius: 50%;
}

.readMore {
  text-decoration: underline;
  font-weight: bold;
  font-size: 0.813rem;
  margin-top: 1rem;

  @media screen and (max-width: 1024px) {
    text-align: center;
    width: 100%;
  }
}
