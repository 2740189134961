.flag32 {
  display: inline-block !important;
  position: relative;
  top: 6px;
  -ms-transform: scale(0.8);
  transform: scale(0.8);
  margin-top: -12px;
}

.card {
  background-color: #ffffff;
  border: 1px solid #e8e8e8;
  text-shadow: none;
  min-width: 630px;
  overflow: auto;
  z-index: 120;

  li {
    cursor: pointer;

    &:hover {
      background-color: #e8e8e8;
      transform: scale(1.05);
      box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.1);
      font-weight: bold;
    }
  }
}

